<template>
  <div class="mt-8">
    <listadoPropiedades />
  </div>
</template>

<script>
import listadoPropiedades from "./ListadoPropiedades.vue";
import { api } from "../../projects/services";

export default {
  components: {
    listadoPropiedades
  },
  async mounted() {
    const { projectId } = this.$route.params;
    this.$store.dispatch("project/currentProject", { projectId });
    this.project = await api.get(projectId);
    this.$store.state.project.breadcrumbs = [
      { pageName: 'Edición de proyectos', 
        route: '/proyectos' 
      },{
        pageName: `Propiedades en ${this.project.name}`,
        route: `/agregar-propiedades/proyecto/${this.$route.params.projectId}`
      }
    ];
  },
  data() {
    return {
      project: null,
    };
  }
};
</script>
