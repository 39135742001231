<template>
  <div id="div-modal-loading" class="vs-con-loading__container">
    <vx-card class="p-2">
      <vs-col vs-type="flex">
        <div>
          <h4 class="mb-4">Propiedad: {{ property.unitNumber }}</h4>
        </div>
      </vs-col>

      <div class="vs-row">
        <div class="p-2">
          <vs-row>
            <vs-col>
              <vs-chip color="success" class="m-2">Paso 1</vs-chip>
              <div>
                <h6 class="mt-3">Información basica:</h6>
              </div>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
        </div>
        <vs-row>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full mt-2">
              <p class="p-2">Tipo de propiedad:</p>
              <v-select
                class="px-2"
                v-model="type"
                :reduce="(type) => type.value"
                :options="propertiesTypes"
                :clearable="false"
                :disabled="true"
              ></v-select>
            </div>
          </vs-col>
          <vs-col vs-w="5" class="p-1 ml-20">
            <div class="w-full sm:w-full mt-2">
              <p class="p-2">Estado:</p>
              <v-select
                class="px-2"
                v-model="status"
                :reduce="(status) => status.name"
                :options="propertiesStatus"
                :clearable="false"
                :disabled="true"
              ></v-select>
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-10">
            <vs-button
              v-if="status != property.status && status != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment('status', status, 'estado actual.', property.id)
              "
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                class="w-full px-2"
                label="Identificador de propiedad"
                v-model.trim="unitNumber"
                name="name"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-5">
            <vs-button
              v-if="unitNumber != property.unit_number && unitNumber != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment(
                  'unitNumber',
                  unitNumber,
                  'identificador o número.',
                  property.id
                )
              "
            ></vs-button>
          </vs-col>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                class="w-full px-2"
                label="Número nivel"
                v-model="level"
                name="level"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-5">
            <vs-button
              v-if="level != property.level && level != 0"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment('level', level, 'número de nivel', property.id)
              "
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                class="w-full px-2"
                label="Número de torre"
                v-model.trim="towerNumber"
                name="towerNumber"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-5">
            <vs-button
              v-if="towerNumber != propertyTower && towerNumber != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment(
                  'tower',
                  towerNumber,
                  'número de torre.',
                  property.id
                )
              "
            ></vs-button>
          </vs-col>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                class="w-full px-2"
                label="Vista"
                v-model.trim="viewStreet"
                name="viewStreet"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-5">
            <vs-button
              v-if="viewStreet != propertyView && viewStreet != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="updateApartment('view', viewStreet, 'vista', property.id)"
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full px-2"
                icon="fa-bed"
                type="number"
                min="0"
                label="Dormitorios"
                v-model.number="rooms"
                name="rooms"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-5">
            <vs-button
              v-if="rooms != property.rooms && rooms != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment('bedrooms', rooms, 'habitaciones.', property.id)
              "
            ></vs-button>
          </vs-col>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full px-2"
                icon="fa-toilet"
                label="Baños"
                type="number"
                min="0"
                v-model.number="bathrooms"
                name="bathrooms"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-5">
            <vs-button
              v-if="bathrooms != property.bathrooms && bathrooms != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment('bathrooms', bathrooms, 'baños.', property.id)
              "
            ></vs-button>
          </vs-col>
        </vs-row>

        <vs-row>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full px-2"
                icon="fa-money-bill"
                label="Precio - Ingresar valor sin comas"
                type="number"
                min="0"
                v-model.number="price"
                name="price"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-5">
            <vs-button
              v-if="price != property.price && price != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="updateApartment('price', price, 'precio.', property.id)"
            >
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full mt-2">
              <p class="p-2">Seleccione tipología:</p>
              <v-select
                class="px-2"
                taggable
                v-model="unitType"
                :options="unitTypes"
                :create-option="(type) => ({ label: type, value: null })"
              ></v-select>
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-10">
            <vs-button
              v-if="
                (property.project_unit_type &&
                  unitType.label != property.project_unit_type.type) ||
                (unitType != '' && !property.project_unit_type)
              "
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment('unitType', unitType, 'Tipología', property.id)
              "
            ></vs-button>
          </vs-col>
        </vs-row>

        <br />
        <vs-row>
          <vs-col>
            <vs-chip color="success" class="m-2">Paso 2</vs-chip>
            <div>
              <h6 class="mt-3">Atributos extras:</h6>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider></vs-divider>

        <vs-row>
          <vs-col class="sm:w-full lg:w-1/2 p-1">
            <div class="w-full sm:w-full mt-2">
              <label for="balcony" class="p-2">Balcón</label>
              <input
                type="checkbox"
                id="balcony"
                v-model="balcony"
                @click="
                  updateApartment('balcony', !balcony, 'balcón.', property.id)
                "
              />
            </div>
          </vs-col>
          <vs-col class="sm:w-full lg:w-1/2 p-1">
            <div class="w-full sm:w-full mt-2">
              <label for="kitchenFurniture" class="p-2"
                >Muebles de Cocina</label
              >
              <input
                type="checkbox"
                id="kitchenFurniture"
                v-model="kitchenFurniture"
                @click="
                  updateApartment(
                    'kitchenFurniture',
                    !kitchenFurniture,
                    'muebles de cocina.',
                    property.id
                  )
                "
              />
            </div>
          </vs-col>
          <vs-col class="sm:w-full lg:w-1/2 p-1">
            <div class="w-full sm:w-full mt-2">
              <label for="closets" class="p-2">Closets</label>
              <input
                type="checkbox"
                id="closets"
                v-model="closets"
                @click="
                  updateApartment('closet', !closets, 'closets.', property.id)
                "
              />
            </div>
          </vs-col>
          <vs-col class="sm:w-full lg:w-1/2 p-1">
            <div class="w-full sm:w-full mt-2">
              <label for="laundry" class="p-2">Lavandería</label>
              <input
                type="checkbox"
                id="laundry"
                v-model="laundry"
                @click="
                  updateApartment(
                    'laundry',
                    !laundry,
                    'lavandería',
                    property.id
                  )
                "
              />
            </div>
          </vs-col>
          <vs-col class="sm:w-full lg:w-1/2 p-1">
            <div class="w-full sm:w-full mt-2">
              <label for="homeAppliances" class="p-2">Electrodomésticos</label>
              <input
                type="checkbox"
                id="homeAppliances"
                v-model="homeAppliances"
                @click="
                  updateApartment(
                    'homeAppliances',
                    !homeAppliances,
                    'electrodomésticos.',
                    property.id
                  )
                "
              />
            </div>
          </vs-col>
          <vs-col class="sm:w-full lg:w-1/2 p-1">
            <div class="w-full sm:w-full mt-2">
              <label for="garden" class="p-2">Jardín</label>
              <input
                type="checkbox"
                id="garden"
                v-model="garden"
                @click="
                  updateApartment('garden', !garden, 'jardín.', property.id)
                "
              />
            </div>
          </vs-col>
        </vs-row>
        <br />
        <vs-row>
          <vs-col>
            <vs-chip color="success" class="m-2">Paso 3</vs-chip>
            <div>
              <h6 class="mt-3">Áreas mts2:</h6>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider></vs-divider>
        <br />
        <vs-row>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full px-2"
                icon="fa-vector-square"
                type="number"
                label="Área habitable"
                color="primary"
                v-model.number="livingArea"
                v-validate="'required'"
                name="living_area"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-10">
            <vs-button
              v-if="livingArea != property.living_area && livingArea != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment(
                  'livingArea',
                  livingArea,
                  'área habitable.',
                  property.id
                )
              "
            ></vs-button>
          </vs-col>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full px-2"
                icon="fa-vector-square"
                type="number"
                label="Área construida"
                color="primary"
                v-model.number="constructionArea"
                v-validate="'required'"
                name="constructionArea"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-10">
            <vs-button
              v-if="
                constructionArea != property.construction_area &&
                constructionArea != ''
              "
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment(
                  'constructionArea',
                  constructionArea,
                  'área construida.',
                  property.id
                )
              "
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full px-2"
                icon="fa-vector-square"
                type="number"
                label="Área jardin"
                color="primary"
                v-model.number="gardenArea"
                v-validate="'required'"
                name="garden_area"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-10">
            <vs-button
              v-if="gardenArea != property.garden_area && gardenArea != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment(
                  'gardenArea',
                  gardenArea,
                  'área jardín.',
                  property.id
                )
              "
            ></vs-button>
          </vs-col>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full px-2"
                icon="fa-vector-square"
                type="number"
                label="Área balcón"
                color="primary"
                v-model.number="balconyArea"
                v-validate="'required'"
                name="balcony_area"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-10">
            <vs-button
              v-if="balconyArea != property.balcony_area && balconyArea != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment(
                  'balconyArea',
                  balconyArea,
                  'área balcón.',
                  property.id
                )
              "
            ></vs-button>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full px-2"
                icon="fa-vector-square"
                type="number"
                label="Área terraza"
                color="primary"
                v-model.number="terraceArea"
                v-validate="'required'"
                name="terrace_area"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-10">
            <vs-button
              v-if="terraceArea != property.terrace_area && terraceArea != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment(
                  'terraceArea',
                  terraceArea,
                  'área de terraza.',
                  property.id
                )
              "
            ></vs-button>
          </vs-col>
          <vs-col vs-w="5" class="p-1">
            <div class="w-full sm:w-full">
              <vs-input
                size="large"
                icon-pack="fas"
                class="w-full px-2"
                icon="fa-vector-square"
                type="number"
                label="Área total"
                color="primary"
                v-model.number="userTotalArea"
                v-validate="'required'"
                name="total_area"
              />
            </div>
          </vs-col>
          <vs-col vs-w="1" class="pt-10">
            <vs-button
              v-if="userTotalArea != property.total_area && userTotalArea != ''"
              icon-pack="feather"
              color="success"
              icon="icon-save"
              class="mt-2"
              @click="
                updateApartment(
                  'totalArea',
                  userTotalArea,
                  'área total.',
                  property.id
                )
              "
            ></vs-button>
          </vs-col>
        </vs-row>
        <br />
        <vs-row>
          <vs-col>
            <vs-chip color="success" class="m-2">Paso 4</vs-chip>
            <div>
              <h6 class="mt-3">Asignación de parqueos a unidad:</h6>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider></vs-divider>
        <br />
        <vs-row>
          <vs-col vs-w="6">
            <div class="w-full sm:w-full mt-2">
              <v-select
                class="px-2"
                v-model="parkingSelect"
                :reduce="(parkingSelect) => parkingSelect.value"
                :options="parkingAvailabe"
                :clearable="false"
                @input="addExtraProperty"
                placeholder="Crear nuevas asignaciones de parqueos"
              ></v-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          v-for="parkingAssigned in parkingsAssigned"
          :key="parkingAssigned.property.id"
        >
          <vs-row>
            <vs-col vs-w="2" class="p-2">
              <div>
                <h6 class="mt-3">
                  Parqueo {{ parkingAssigned.property.unit_number }}:
                </h6>
              </div>
            </vs-col>
            <vs-col vs-w="2" class="p-4">
              <button
                class="p-0 w-6 h-6 bg-gray-300 rounded-full hover:bg-gray-600 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
                @click="removeExtraProperty(parkingAssigned.property.id)"
              >
                <svg class="svg-icon" viewBox="0 0 20 20">
                  <path
                    fill="#FFFFFF"
                    d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                  ></path>
                </svg>
              </button>
            </vs-col>
          </vs-row>
          <vs-col vs-w="3" class="p-1">
            <div
              class="flex p-2 px-5 md:mt-0 mt-1 rounded-lg bg-gray-100 text-gray-600"
            >
              <p class="ml-2">Nivel {{ parkingAssigned.property.level }}</p>
            </div>
          </vs-col>
          <vs-col vs-w="3" class="p-1">
            <div
              class="flex p-2 px-5 md:mt-0 mt-1 rounded-lg bg-gray-100 text-gray-600"
            >
              <p class="ml-2">
                Tipo:
                {{
                  propertyAttribute(parkingAssigned.property, "PARKING_TYPE")
                }}
              </p>
            </div>
          </vs-col>
          <vs-col vs-w="3" class="p-1">
            <div
              class="flex p-2 px-5 md:mt-0 mt-1 rounded-lg bg-gray-100 text-gray-600"
            >
              <p class="ml-2">
                Precio: {{ getFormatCurrency(parkingAssigned.property.price) }}
              </p>
            </div>
          </vs-col>
          <vs-col vs-w="3" class="p-1">
            <div
              class="flex p-2 px-5 md:mt-0 mt-1 rounded-lg bg-gray-100 text-gray-600"
            >
              <p class="ml-2">
                Area {{ parkingAssigned.property.total_area }}m²
              </p>
            </div>
          </vs-col>
        </vs-row>
        <br />
        <vs-row>
          <vs-col>
            <vs-chip color="success" class="m-2">Paso 5</vs-chip>
            <div>
              <h6 class="mt-3">Asignación de bodegas a unidad:</h6>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider></vs-divider>
        <br />
        <vs-row>
          <vs-col vs-w="6">
            <div class="w-full sm:w-full mt-2">
              <v-select
                class="px-2"
                v-model="warehouseSelect"
                :reduce="(warehouseSelect) => warehouseSelect.value"
                :options="warehouseAvailabe"
                :clearable="false"
                @input="addExtraProperty"
                placeholder="Crear nuevas asignaciones de bodegas"
              ></v-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          v-for="warehouseAssigned in warehousesAssigned"
          :key="warehouseAssigned.property.id"
        >
          <vs-row>
            <vs-col vs-w="2" class="p-2">
              <div>
                <h6 class="mt-3">
                  Bodega {{ warehouseAssigned.property.unit_number }}:
                </h6>
              </div>
            </vs-col>
            <vs-col vs-w="2" class="p-4">
              <button
                class="p-0 w-6 h-6 bg-gray-300 rounded-full hover:bg-gray-600 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
                @click="removeExtraProperty(warehouseAssigned.property.id)"
              >
                <svg class="svg-icon" viewBox="0 0 20 20">
                  <path
                    fill="#FFFFFF"
                    d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                  ></path>
                </svg>
              </button>
            </vs-col>
          </vs-row>
          <vs-col vs-w="4" class="p-1">
            <div
              class="flex p-2 px-5 md:mt-0 mt-1 rounded-lg bg-gray-100 text-gray-600"
            >
              <p class="ml-2">Nivel {{ warehouseAssigned.property.level }}</p>
            </div>
          </vs-col>
          <vs-col vs-w="4" class="p-1">
            <div
              class="flex p-2 px-5 md:mt-0 mt-1 rounded-lg bg-gray-100 text-gray-600"
            >
              <p class="ml-2">
                Precio:
                {{ getFormatCurrency(warehouseAssigned.property.price) }}
              </p>
            </div>
          </vs-col>
          <vs-col vs-w="4" class="p-1">
            <div
              class="flex p-2 px-5 md:mt-0 mt-1 rounded-lg bg-gray-100 text-gray-600"
            >
              <p class="ml-2">
                Area {{ warehouseAssigned.property.total_area }}m²
              </p>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import { propertyApi } from "../services";
import { unitTypeApi } from "../../projects/services";
import { sentryCaptureException } from "../../../helpers/Sentry";
import currencies from "../../../helpers/currencies";

export default {
  data() {
    return {
      property: {},
      unitNumber: "",
      level: 0,
      price: 0,
      status: "",
      type: "",
      rooms: "",
      bathrooms: "",
      balcony: false,
      kitchenFurniture: false,
      closets: false,
      laundry: false,
      homeAppliances: false,
      garden: false,
      propertiesTypes: [],
      propertiesStatus: [],
      unitType: {},
      unitTypes: [],
      livingArea: 0,
      terraceArea: 0,
      gardenArea: 0,
      balconyArea: 0,
      totalArea: 0,
      constructionArea: 0,
      towerNumber: "",
      viewStreet: "",
      parkingAvailabe: [],
      parkingSelect: "",
      parkingsAssigned: [],
      warehouseAvailabe: [],
      warehouseSelect: "",
      warehousesAssigned: [],
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    userTotalArea: {
      get() {
        if (this.totalArea > 0) {
          return this.totalArea;
        }

        const constructionArea = this.constructionArea || 0;
        const terraceArea = this.terraceArea || 0;
        const gardenArea = this.gardenArea || 0;
        const balconyArea = this.balconyArea || 0;

        return (
          constructionArea +
          terraceArea +
          gardenArea +
          balconyArea
        ).toFixed(2);
      },
      set(value) {
        this.totalArea = parseFloat(value);
      },
    },
    propertyTower() {
      return this.propertyAttribute(this.property, "TOWER_NUMBER") || "";
    },
    propertyView() {
      return this.propertyAttribute(this.property, "VIEW_STREET") || "";
    },
  },
  async mounted() {
    const propertyId = this.$store.getters["property/selectedPropertyId"];
    await this.loadProperty(propertyId);
  },
  components: {
    "v-select": vSelect,
  },
  watch: {
    "$store.state.property.selectedPropertyId": async function (value) {
      await this.loadProperty(value);
    },
  },
  methods: {
    async loadProperty(propertyId) {
      const projectId = this.$store.state.project.currentProjectId;
      const propertiesStatus = await propertyApi.getPropertyStatus();

      this.propertiesStatus = propertiesStatus.map((obj) => {
        return { label: obj.name, value: obj.id };
      });

      this.$vs.loading({
        scale: 0.6,
      });

      const propertiesTypes = await propertyApi.getProperyTypes();

      this.$vs.loading.close();

      this.propertiesTypes = propertiesTypes.map((obj) => {
        return { label: obj.name, value: obj.id };
      });

      const unitTypes = await unitTypeApi.list(projectId);

      this.unitTypes = unitTypes.map((obj) => {
        return { label: obj.type, value: obj.id };
      });

      if (propertyId) {
        this.property = await propertyApi.get(propertyId);
        this.mapPropertyValues();
      }

      this.parkingSelect = [];
      const parkingAvailabe = await propertyApi.listPropertyAvailable(
        projectId,
        "PARKING"
      );

      this.parkingAvailabe = parkingAvailabe.map((obj) => {
        return { label: obj.unit_number, value: obj.id };
      });
      this.parkingsAssigned = this.property.assigned_properties.filter(
        (obj) => obj.property.property_type_id === "PARKING"
      );

      this.warehouseSelect = [];
      const warehouseAvailabe = await propertyApi.listPropertyAvailable(
        projectId,
        "WAREHOUSE"
      );

      this.warehouseAvailabe = warehouseAvailabe.map((obj) => {
        return { label: obj.unit_number, value: obj.id };
      });
      this.warehousesAssigned = this.property.assigned_properties.filter(
        (obj) => obj.property.property_type_id === "WAREHOUSE"
      );
    },
    async updateApartment(
      fieldForUpdate,
      valueToAdd,
      descriptionToUser,
      propertyId
    ) {
      try {
        const fields = {
          [fieldForUpdate]: valueToAdd,
        };

        if (fieldForUpdate === "unitType") {
          fields["unitType"] = valueToAdd.value;
        }

        if ("price" in fields) fields["price"] = String(fields["price"]);

        await propertyApi.updateProperty(propertyId, fields);

        if (
          [
            "living_area",
            "terrace_area",
            "garden_area",
            "balcony_area",
            "construction_area",
            "total_area",
          ].includes(fieldForUpdate)
        ) {
          await propertyApi.updateProperty(propertyId, {
            area: this.totalArea,
          });
        }

        this.$vs.notify({
          title: `👌🎉 El campo ${descriptionToUser}`,
          text: `Fue editado correctamente.`,
          color: "success",
        });

        this.property = await propertyApi.get(propertyId);
      } catch (e) {
        sentryCaptureException(e, "property");
        this.$vs.notify({
          title: `Error`,
          text: `Ha ocurrido un error editando el campo`,
          color: "danger",
        });
      }
    },
    async mapPropertyValues() {
      if (this.property) {
        const property = this.property;
        this.type = property.property_type.id;
        this.status = property.status;
        this.level = property.level;
        this.unitNumber = property.unit_number;
        this.price = property.price;
        this.rooms = property.rooms;
        this.bathrooms = property.bathrooms;
        this.livingArea = property.living_area;
        this.terraceArea = property.terrace_area;
        this.gardenArea = property.garden_area;
        this.balconyArea = property.balcony_area;
        this.totalArea = property.total_area;
        this.constructionArea = property.construction_area;

        if (property.project_unit_type) {
          this.unitType = property.project_unit_type.type;
        }

        for (const extra of property.property_attribute_values) {
          if (extra.property_attribute == "BALCONY")
            this.balcony = JSON.parse(extra.value);
          if (extra.property_attribute == "KITCHEN_FURNITURE")
            this.kitchenFurniture = JSON.parse(extra.value);
          if (extra.property_attribute == "CLOSET")
            this.closets = JSON.parse(extra.value);
          if (extra.property_attribute == "LAUNDRY")
            this.laundry = JSON.parse(extra.value);
          if (extra.property_attribute == "HOME_APPLIANCES")
            this.homeAppliances = JSON.parse(extra.value);
          if (extra.property_attribute == "GARDEN")
            this.garden = JSON.parse(extra.value);
        }

        this.towerNumber =
          this.propertyAttribute(this.property, "TOWER_NUMBER") || "";
        this.viewStreet =
          this.propertyAttribute(this.property, "VIEW_STREET") || "";
      }
    },
    propertyAttribute(attributeValues, value) {
      const attribute = attributeValues.property_attribute_values.find(
        (propertyValue) => propertyValue.property_attribute == value
      );

      if (!attribute) return null;

      return attribute.value;
    },
    async addExtraProperty(extraPropertyId) {
      const propertyId = this.$store.getters["property/selectedPropertyId"];
      await propertyApi.allocateProperty(propertyId, extraPropertyId);
      await this.loadProperty(propertyId);
    },
    async removeExtraProperty(extraPropertyId) {
      const propertyId = this.$store.getters["property/selectedPropertyId"];
      await propertyApi.deallocateProperty(propertyId, extraPropertyId);
      await this.loadProperty(propertyId);
    },
    getFormatCurrency(value) {
      return currencies.currencyFormatter({ value });
    },
  },
};
</script>

<style lang="scss" scoped>
.inputApartament {
  width: 98%;
}

.imgForEdit {
  margin-top: 68px;
  width: 130px;
}

@media (min-width: 300px) and (max-width: 600px) {
  #avatar-col {
    display: block;
    margin: 0 auto;
  }
}
</style>